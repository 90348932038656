import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Error from 'next/error'

import Page from 'components/common/Page'
import BlogContent from 'components/common/BlogContent'
import Card from 'components/common/cards/Card'
import BlogHero from 'components/common/BlogHero'
import BlockTitle from 'components/common/blocks/BlockTitle'
import SkeletonTitle from 'components/common/skeletons/SkeletonTitle'
import Custom404 from 'pages/404'

import getNested from 'utility/getNested'
import * as blogQuery from 'utility/queries/blog'
import { sanitizeInput } from 'utility/string'
import { WORDPRESS_BASE } from 'utility/endpoints'
import colors from 'utility/colors'

const GenericWPPage = ({ page }) => {
  if (!page) return <Custom404 />

  const pageTitle = getNested(page, 'title', 'rendered')
  const pageBody = getNested(page, 'content', 'rendered')

  let backgroundUrl
  if (
    getNested(page, '_embedded', 'wp:featuredmedia') &&
    getNested(page, '_embedded', 'wp:featuredmedia')[0].source_url
  ) {
    backgroundUrl = `${WORDPRESS_BASE}${page._embedded['wp:featuredmedia'][0].source_url}`
  }

  const SkeletonLoading = (
    <Container>
      <Row>
        <Col xs="12">
          <SkeletonTitle style={{ marginBottom: '30px' }} />
          <SkeletonTitle style={{ marginBottom: '30px' }} />
          <SkeletonTitle style={{ marginBottom: '30px' }} />
          <SkeletonTitle style={{ marginBottom: '30px' }} />
          <SkeletonTitle style={{ marginBottom: '30px' }} />
        </Col>
      </Row>
    </Container>
  )

  return (
    <Page
      title={getNested(page, 'acf', 'meta_title') || pageTitle}
      description={
        getNested(page, 'acf', 'meta_description') ||
        sanitizeInput(pageBody) ||
        `Start saving money on your property taxes in Texas!`
      }
      image={backgroundUrl}
      footer
      wedge
      fluid
    >
      <>
        {backgroundUrl ? (
          <BlogHero
            height={'50vh'}
            md={'12'}
            backgroundImage={backgroundUrl}
            titleStyle={{ textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center' }}
            title={pageTitle && pageTitle.trim()}
          />
        ) : null}

        <Container
          style={{
            paddingBottom: 50,
            marginTop: backgroundUrl ? -50 : 0,
            maxWidth: 1200,
          }}
        >
          {backgroundUrl ? null : (
            <Row style={{ marginTop: 20 }}>
              <Col>
                <BlockTitle
                  cardStyle={{ padding: 30 }}
                  titleStyle={{ fontSize: 32 }}
                  background={colors.FOOTER}
                  title={pageTitle && pageTitle.trim()}
                />
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <Card style={{ minHeight: '350px' }}>
                {pageBody ? (
                  <BlogContent style={{ marginTop: 10 }}>
                    {pageBody}
                  </BlogContent>
                ) : (
                  SkeletonLoading
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </Page>
  )
}

GenericWPPage.getInitialProps = async ({ query }) => {
  const { state } = query
  const page = await blogQuery.fetchPageBySlug(state)
  return { page }
}

export default GenericWPPage
