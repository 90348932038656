import React from 'react';
import styled from 'styled-components';
import Subtitle from 'components/common/Subtitle';
import Card from 'components/common/cards/Card';
import Text from 'components/common/Text';
import {FaSmile} from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import colors from 'utility/colors';

const BlockTitle = ({title, noShadow, text, md, lg, icon, largeTitle, background=colors.OFF_WHITE, padding=20, contentPadding=0, titleStyle, backgroundImage, cardStyle={}, iconStyle={}, iconContainerStyle={}, rowStyle={}, children}) => {

  const iconToStyle = icon || FaSmile
  const StyledIcon = styled(iconToStyle)`
    color: ${colors.GRAY};
    font-size: 96px;
  `
  let titleBaseStyle = {
    color: colors.WHITE, fontSize: largeTitle ? 38 : null, fontWeight: 'bold', ...titleStyle
  }

  if(largeTitle)
    titleBaseStyle.fontSize = 38;

  return <Card 
    noShadow={noShadow}
    style={{
    backgroundColor: background,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    padding,
    overflow: 'hidden',
    position: 'relative',
    ...cardStyle}}>
    <>
      {backgroundImage ? <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'black', opacity: '.5'}}/> : null}
      <Row style={{padding: contentPadding, position: 'relative', ...rowStyle}}>
        <Col xs='12' md={md ? md : icon ? '8' : '12'} lg={lg ? lg : icon ? '9' : '12'}>
          {title && <Subtitle size={28} style={{...titleBaseStyle}}>{title}</Subtitle>}
          {text && <Text size={18} style={{color: colors.WHITE}}>{text}</Text>}
        </Col>
        {icon && (
          <Col xs='12' md='4' lg='3' style={{textAlign: 'center', ...iconContainerStyle}} className="d-none d-md-block">
            <StyledIcon style={{...iconStyle}}/>
          </Col>
        )}
        {children}
      </Row>
    </>
  </Card>
}

export default BlockTitle;