import sanitizeHtml from 'sanitize-html'
import { decode } from 'html-entities'

export function convertSpecialCharacters(input) {
	let specialCharacterCode = input.match(/&#(.*);/)
	if (specialCharacterCode) {
		let convertedCode = String.fromCharCode(specialCharacterCode.pop())
		return input.replace(specialCharacterCode[0], convertedCode)
	} else {
		return input
	}
}

export function createMarkup(string) {
	return { __html: string }
}

function prettyTrim(text, n, useWordBoundary) {
	if (!text) return text

	if (text.length <= n) {
		return text
	}
	var subString = text.substr(0, n - 1)
	return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + ' ...'
}

export function sanitizeInput(htmlString, skipTrim, trimLength = 320) {
	let string = htmlString
	if (!skipTrim) {
		string = prettyTrim(htmlString, trimLength, true)
	}
	return sanitizeHtml(string, {
		allowedTags: [''],
		allowedAttributes: {},
	})
}

export const cleanHtml = (html) => {
	if (!html) return html

	const regex = /(<([^>]+)>)/gi
	return decode(html.replace(regex, '')).trim()
}

export function truncateOnWord(str, maxLength) {
	if (!str) return str

	//trim the string to the maximum length
	var trimmedString = str.substr(0, maxLength).toLowerCase()

	//re-trim if we are in the middle of a word
	return (trimmedString = trimmedString.substr(
		0,
		Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
	))
}
export function capitalize(string) {
	if (!string) return string
	return string.charAt(0).toUpperCase() + string.substr(1)
}
export function capitalizeAll(string) {
	if (!string) return string
	return string
		.toLowerCase()
		.split(' ')
		.map((str) => capitalize(str))
		.join(' ')
}

export const pluralize = (str, count) => {
	return count === 1 ? str : `${str}s`
}

export const toSlug = (text) => {
	if (!text) return ''

	return (
		text
			.toString()
			.normalize('NFD') // split an accented letter in the base letter and the acent
			.replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
			.toLowerCase()
			// .trim()
			.replace(/\s+/g, '-')
			.replace(/[^\w\-]+/g, '')
			.replace(/\-\-+/g, '-')
	)
}

export default {
	toSlug,
}
